<script setup>
import NavBar from './components/NavBar.vue';
import AppAlerts from './components/AppAlerts.vue';
import { useRoute } from "vue-router";

const route = useRoute();
</script>

<template>
  <div class="bg-base-200 h-screen">
    <NavBar />
    <div id="main-content" class="pt-20 ">

      <!-- <AppAlerts /> -->

      <router-view v-slot="{ Component }">
        <Transition :name="route.meta.transition || 'fade'" mode="out-in">
          <component :is="Component" />
        </Transition>
      </router-view>
    </div>
  </div>
</template>

<style scoped>
.slide-left-enter-from {
  opacity: 0;
  transform: translateX(100px);
}

.slide-left-enter-active {
  transition: all 0.2s ease-out;
}

.slide-left-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}

.slide-left-leave-active {
  transition: all 0.2s ease-in;
}


.slide-right-enter-from {
  opacity: 0;
  transform: translateX(-100px);
}

.slide-right-enter-active {
  transition: all 0.2s ease-out;
}

.slide-right-leave-to {
  opacity: 0;
  transform: translateX(100px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
