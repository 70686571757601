<template>
    <div>
        <div class="home px-3">
            <h1>Home</h1>
            <div class="flex align-center justify-start gap-2">
                <button class="btn btn-primary" 
                        :disabled="transactionsStore.loading"
                        @click="load">
                    <template v-if="transactionsStore.loading">
                        Loading...
                    </template>
                    <template v-else>
                        Load
                    </template>
                </button>
                <template v-if="transactionsStore.errorText">
                    <span class="text-red-800"> {{ transactionsStore.errorText }} </span>
                </template>
            </div>
        </div>
        <p> Last Updated: {{ transactionsStore.transactions.update_sent_at }} </p>
        <div class="flex flex-col text-gray-900 bg-white bg-clip-border">
            <nav class="flex min-w-[240px] flex-col gap-1 p-2 text-base">
                <template v-for="driver in transactionsStore.transactions">
                    <div role="button"
                        class="flex items-center p-3 mt-2 leading-tight transition-all outline-none text-start hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900 font-bold border-b-2 border-black">
                        {{ driver.driver }}
                    </div>
                    <div role="button"
                        class="flex items-center p-3 leading-tight transition-all outline-none text-start hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900 border-b border-gray-300"
                        v-for="(value, index) in driver.manifests" @click="rowClick(driver.id, index)">
                        <div class="flex grow">
                            {{ index }}
                        </div>
                        <div class="flex justify-end">
                            <span class="bg-red-500 rounded-full">
                                <div class="badge badge-primary badge-lg">{{ driver.manifests[index].transactions.length
                                    }}</div>
                            </span>
                        </div>
                        <div class="flex justify-end ml-2">
                            <ChevronRightIcon class="size-6 text-black" />
                        </div>

                    </div>
                </template>
            </nav>
        </div>

        <!--
        <pre style="border: 3px solid darkblue; min-height: 1rem; min-width: 1rem;"
        >{{ transactionsStore.transactions }}</pre>
        -->
    </div>

</template>

<script setup>
import { useTransactionsStore } from '@/stores/transactions';
import { useRouter } from "vue-router";
import { ChevronRightIcon } from '@heroicons/vue/24/solid'

const router = useRouter();

const transactionsStore = useTransactionsStore();

// console.log(transactionsStore.transactions)

async function load() {
    await transactionsStore.loadTransactions();
}

function rowClick(driverid, manifestid) {
    //console.log(tr, window.navigator.onLine)
    router.push({ path: '/manifest/' + driverid + '/' + manifestid });
}

</script>
