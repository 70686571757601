import { defineStore } from "pinia"
import { useAuthStore } from "./auth"


export const useTransactionsStore = defineStore('transactions', {
    state() {
        return {
            minRoot: import.meta.env.VITE_MIN_ROOT,
            transactions: {},
            errorText: "",
            loading: false,
        }

    },
    actions: {
        async loadTransactions() {
            this.loading = true;
            this.errorText = "";

            let driverid, api_url, res, data;
            const authStore = useAuthStore();


            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", "Bearer " + authStore.lat)

            api_url = this.minRoot + '/api/1.1/pwa/user/driver-data'

            fetch(api_url, {
                method: 'GET',
                headers: myHeaders,
            }).then((response) => {
                if(!response.ok) {
                    throw new Error(`HTTP error, status = ${response.status}`);
                }

                return response.json(); // read response json into next `.then()` statement
            }).then((transactionJson) => {
                this.transactions = transactionJson;
                console.log('responded', this.transactions, transactionJson);
                this.loading = false;
            }).catch((error) => {
                console.log('loadTransactions error', error);
                this.updateErrorText(error);
                this.loading = false;
            });


            // res = await fetch(api_url, {
            //     method: 'GET',
            //     headers: myHeaders
            // });
            // data = await res.json();
            // this.transactions = data;
            // this.loading = false;
        },
        updateErrorText(errorObj) {
            console.log('errorObj', errorObj);
            console.log('error.name', errorObj.name);
            console.log('error.message', errorObj.message);
            console.log('error.stack', errorObj.stack);
            console.log('error.fileName', errorObj.fileName);
            console.log('error.lineNumber', errorObj.lineNumber);

            if(errorObj.message == "Failed to fetch") { // do NOT change: exact case-match string
                this.errorText = "Unable to reach server. Check your internet connection.";
            } else {
                this.errorText = `${errorObj.name}: ${errorObj.message}`;
            }
            
        },

        $reset() {
            this.transactions;
            this.loading = false;
        }

    },
    getters() {

    },
    persist: true
});
